import * as API from "@/services/API";

export default {
    paginateShops(page_number,searchData) {          
            if (searchData.shopId != '' && searchData.shopId != 0) {
                const headers = {};
                headers['shopId'] = searchData.shopId;
            
        return API.apiClient.get('/api/shops?page=' + page_number,{ 
            headers: headers
            
        });
    }
        else{ 
            return API.apiClient.get('/api/shops?page=' + page_number);
        }
       
    },
    async replaceTopicQuestionAnswers(payload){
        return await API.apiClient.post('/api/replace/topics/questions',payload);
    },
    async copySingleQuestionAnswers(payload){
        return await API.apiClient.post('/api/replace/single/questions',payload);
    },
    async updateTopicQuestionAnswers(payload){
        return await API.apiClient.post('/api/topics',payload);
    },

    getTopicQuestion(topicsId){
        return API.apiClient.get('/api/topics/'+topicsId);
    },
    updateTopicContent(payload, topicId){
        return API.apiClient.put('/api/topics/'+topicId,payload);
    },
    async getAllTopics(searchData,page){
        const headers = {};
        headers['is-exam-question'] = searchData.is_exam_question;
        return await API.apiClient.get('/api/topics?page='+page,{ 
            headers: headers
        });
    }
};
