<template>
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Topics</h4>
                  <p class="card-category mb-0">
                    Here you can Edit Topics's data
                  </p>
                </div>
                <div class="content-card-right ms-auto">
                  <a
                    href="#edit"
                    data-bs-toggle="modal"
                    data-bs-target="#add"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <!-- <div
                        class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0"
                      >
                        <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                          <i class="bi bi-funnel me-1 position-relative"></i
                          >Filters
                        </h4>
                      </div> -->
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                        <!-- <div class="position-relative w-100">
                          <Multiselect v-model="searchData.shopId"
                          :options="search_shops" class="custom-multiselect
                          table-header-multi h-auto" placeholder="Search Shop"
                          :searchable="true" :showOptions="true" mode="single"
                          label="shopName" trackBy = "shop_name" valueProp="id"
                          required: true />

                          <i
                            class="bi bi-search position-absolute search-multi"
                          ></i>
                        </div> -->
                      </div>
                    </div>
                  </form>

                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Id</th>
                          <th class="text-capitalize">Title</th>
                          <th class="text-capitalize">
                            Exam Questions
                            <!-- <select
                              class="form-select"
                              v-model="examQuestionFilter"
                            >
                              <option value="">All</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select> -->
                          </th>
                          <!-- <th class="text-capitalize">content</th> -->
                          <!-- <th class="text-capitalize">created On</th> -->
                          <th class="text-capitalize">Exam Mandatory</th>
                          <th class="text-capitalize">Quiz Style</th>
                          <th class="text-capitalize text-center">
                            Edit content
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="!pageData.allTopics && pageData.allTopics==''">
                         <td colspan="100%" class="text-center">
                          <span class="custom-animated-loader d-inline-block position-relative"></span>    
                        </td>
                        </tr>

                        <tr 
                          v-for="topic in pageData.allTopics.data"
                          :key="topic.id"
                        >
                          <td>{{ topic.id }}</td>
                          <td>{{ topic.title }}</td>
                          <td>{{ topic.has_questions == 1 ? 'Yes' : 'No' }}</td>
                          <!-- <td>{{ topic.content }}</td> -->
                          <td>
                            {{ topic.is_exam_mandatory == 1 ? 'Yes' : 'No' }}
                          </td>
                          <td>{{ topic.quiz_style == 1 ? 'Yes' : 'No' }}</td>
                          <td>
                            <a
                              href="#edit"
                              data-bs-toggle="modal"
                              data-bs-target="#edit"
                              @click="setEditContent(topic.id, topic.content)"
                              class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle border-0"
                            >
                              edit
                            </a>
                          </td>
                        </tr>
                        
                      </tbody>
               
                     
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              >
                <p   v-if="
                    pageData.allTopics &&
                    pageData.allTopics.data &&
                    pageData.allTopics.data.length > 0
                  " class="d-lg-inline-block table-data">
                  Showing {{ pageData.allTopics.meta.from??'' }} to {{ pageData.allTopics.meta.to??'' }} of {{ pageData.allTopics.meta.total??'' }} entries..
                </p>

                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div
                  v-if="
                    pageData.allTopics &&
                    pageData.allTopics.data &&
                    pageData.allTopics.data.length > 0
                  "
                  class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper"
                >
                  <v-pagination
                    class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="paginationPage"
                    :pages="
                      pageData.allTopics
                        ? pageData.allTopics.meta.last_page
                        : null
                    "
                    :range-size="1"
                    @update:modelValue="getAllTopics"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- <shopkeeper-add-modal @alert-event="showGrowl"></shopkeeper-add-modal> -->
  <TopicEditModal
    @alert-event="showGrowl"
    :topicId="topicId"
    :topicContent="topicContent"
  ></TopicEditModal>
  <Alert
    @alert-event="showGrowl"
    v-if="growlMessage"
    :name="alert_m.name"
    :alertHeading="alert_m.heading"
    :alertDescription="alert_m.description"
  >
  </Alert>
</template>

<script>
import '@/assets/scss/pages/shop.scss';
import shopServices from '@/services/ShopServices';
import { onMounted, ref, reactive, watch } from 'vue';
import Alert from '@/components/alert';
//import pagination from '@/components/pagination';
import VPagination from '@hennge/vue3-pagination';
//import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import ShopkeeperAddModal from '@/components/modals/ShopkeeperAddModal.vue';
import TopicEditModal from '@/components/modals/TopicEditModal.vue';
// Add this for multiselect
//import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
export default {
  name: 'lessonTopics',
  components: {
    VPagination,
    //ShopkeeperAddModal,
    TopicEditModal,
    // Multiselect,
    Alert,
  },
  setup() {
    const paginationPage = ref(1);
    const total_count = ref(0);
    const entries_per_page = ref(0);
    const search_shops = ref([]);
    const shops = ref([]);
    const total_pages = ref(0);
    const current_page = ref(1);
    const topicId = ref(0);
    const topicContent = ref('');
    const examQuestionFilter = ref('');
    const alert_m = reactive({
      name: '',
      heading: '',
      description: '',
    });
    const pageData = reactive({
      allTopics: '',
    });

    const growlMessage = ref(false);

    const getAllTopics = async (val = null) => {
      pageData.allTopics='';
      const serarchQuery = {
        is_exam_question: examQuestionFilter.value,
      };
      await shopServices
        .getAllTopics(serarchQuery, val ? val : 1)
        .then(async (response) => {
          pageData.allTopics = response.data;
        })
        .catch((e) => {
          alert(e);
        });
    };
    onMounted(() => {
      getAllTopics();
    });
    const setEditContent = (id, content) => {
      topicId.value = id;
      topicContent.value = content;
    };

    const showGrowl = (alertType) => {
      if (alertType == 'add') {
        alert_m.name = 'success';
        alert_m.description = 'Record Added Successfully';
      } else if (alertType == 'edit') {
        alert_m.name = 'success';
        alert_m.description = 'Record Updated Successfully';
      } else {
        alert_m.name = 'danger';
        alert_m.description = 'Invalid Pattern!';
      }

      alert_m.heading = 'LessonTopic';
      growlMessage.value = true;
      setTimeout(() => {
        growlMessage.value = false;
      }, 5000);
    };

    watch(
      () => examQuestionFilter.value,
      (currentValue, oldValue) => {
        if (currentValue != oldValue) {
          getAllTopics();
        }
      }
    );

    return {
      shops,
      total_pages,
      current_page,
      getAllTopics,
      topicId,
      showGrowl,
      alert_m,
      growlMessage,
      entries_per_page,
      search_shops,
      total_count,
      paginationPage,
      pageData,
      setEditContent,
      topicContent,
      examQuestionFilter,
    };
  },
};
</script>
