import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/pages/Login.vue';
import Wrapper from '@/pages/Wrapper.vue';
import Billing from '@/pages/Billing.vue';
import Return from '@/pages/Return.vue';
import Topics from '@/pages/lessonTopics.vue';
import Companies from '@/pages/Companies.vue';
import Product from '@/pages/Product.vue';
import Salesman from '@/pages/Salesman.vue';
import SalesmanShops from '@/pages/SalesmanShops.vue';
import InventoryDaily from '@/pages/InventoryDaily.vue';
import BalanceSheetDaily from '@/pages/BalanceSheetDaily.vue';
import BalanceSheetShop from '@/pages/BalanceSheetShop.vue';
import BalanceSheetSalesman from '@/pages/BalanceSheetSalesman.vue';
import addBilling from '@/pages/addBilling.vue';
import addPayment from '@/pages/addPayment.vue';
import addReturn from '@/pages/addReturn.vue';

const routes: Array<RouteRecordRaw> =[
    {path: '',name: 'Login',component: Login},

    {
      path: '',
      component: Wrapper,
      children: [
        {path: '/topics', component: Topics},
        {path: '/companies', component: Companies},
        {
          path: '/companies/products/:company_id',
          component: Product,
          props: true,
        },
        {path: '/salesman', component: Salesman},
        {
          path: '/salesman/shops/:salesman_id',
          component: SalesmanShops,
          props: true,
        },
        {
          path: '/billing',
          component: Billing,
          
        },
        {
          path: '/billing/add',
          component: addBilling,
          
        },
        
        {
          path: '/return',
          component: Return,
          
        },
        {
          path: '/return/add',
          component: addReturn,
          
        },
        {
          path: '/inventory-daily',
          component: InventoryDaily,
          
        },
        {
          path: '/balancesheet/daily',
          component: BalanceSheetDaily,
          
        },
        {
          path: '/balancesheet/shop',
          component: BalanceSheetShop,
          
        },
        {
          path: '/balancesheet/salesman',
          component: BalanceSheetSalesman,
          
        },
        {
          path: '/payment/add',
          component: addPayment,
          
        },
      ]
    }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
