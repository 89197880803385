<template>
    <!-- Inner content -->
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative py-4">
                <div class="content-card-left">
                  <h4 class="card-title text-white">Balance Sheet</h4>
                </div>
                 <div class="content-card-right ms-auto d-flex flex-wrap">
                  <a
                    href="#"
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle me-1"
                    @click="counter_all++"
                  >
                    <img
                      src="@/assets/images/pdf.png"
                      alt="pdf-image"
                      class="small-image white-image"
                    />
                  </a>
                </div>
                            </div>
                            <div class="card-body pt-0" >
                                <div class="header-content">
                                    <form class="table-header mx-0 mb-1 custom-form position-sticky">
                                        <div class="row m-0 align-items-center">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0">
                                                <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                                                        <i class="bi bi-funnel me-1 position-relative"></i>Filters
                                                </h4>
                                            </div>
                                            
                                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-lg-0 px-md-0 px-sm-3 px-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3 ms-auto">
                                                <v-date-picker class="custom-calendar custom-single-calendar left-caret-none" v-model="searchDate">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <div class="position-relative calendar-input-wrapper clearfix">
                                                            <span class="d-inline-block me-2 float-left">Date</span>
                                                            <i class="bi bi-calendar3 position-absolute icon-left-placement"></i>
                                                            <input
                                                                class="icon-left position-relative float-left w-100"
                                                                :value="inputValue"
                                                                v-on="inputEvents"
                                                            />
                                                        </div>
                                                    </template>
                                                </v-date-picker>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="table-responsive mb-0">
                                        <table id="my-table"  class="custom-table w-100">
                                            <thead>
                                                <tr>
                                                    <th class="text-capitalize">Date</th>
                                                    <th class="text-capitalize">Salesman Name</th>
                                                    <th class="text-capitalize">Debit</th>
                                                    <th class="text-capitalize">Credit</th>
                                                    <th class="text-capitalize">Return</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="daily in dailyData" :key="daily.id">
                                                    <td>{{formatDate(searchDate)}}</td>
                                                    <td>{{daily.first_name+" "+daily.last_name}}</td>
                                                    <td v-if="daily.debit!=0">{{daily.debit}}</td>
                                                    <td v-else>---</td>
                                                    <td v-if="daily.credit!=0">{{daily.credit}}</td>
                                                    <td v-else>---</td>
                                                    <td v-if="daily.return!=0">{{daily.return}}</td>
                                                    <td v-else>---</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
	<daily-check-pdf :counter_all="counter_all" :date="searchDate"></daily-check-pdf>
</template>
<script>
	import {ref, onMounted, watch} from 'vue';
    import "@/assets/scss/pages/_general.scss";
    import moment from 'moment';
    import balanceSheetServices from '@/services/BalanceSheetServices';
    import DailyCheckPdf from '@/components/pdf/DailyCheckPdf.vue';
	export default {
         components: {
	   DailyCheckPdf,
        },
		setup() {
            const dailyData=ref([]);
            const counter_all = ref(0);
			const searchDate= ref(new Date());
            const loadData= async()=>{
                const date=moment(String(searchDate.value)).format('YYYY-MM-DD');
                await balanceSheetServices.getDailyCheck(date)
                .then(response=>{
                    dailyData.value= response.data.data;
                })
                .catch(error => {  
                    alert("error");
                });
            }
            onMounted( () => {
                loadData();
            });
            watch(()=>searchDate.value, (currentValue, oldValue) => {
                if(currentValue!=oldValue && currentValue!=""){
                    loadData();
                }
            });

            const formatDate = (value)=>{
                if (value) {
                    return moment(String(value)).format('MMM Do YY');
                }
            }
			return {
				dailyData,
				searchDate,
                formatDate,
                counter_all
	        }
		}
	}
</script>