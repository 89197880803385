<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import BillingServices from '@/services/BillingServices';
import moment from 'moment';
export default {
  props: {
    billing_id: Number,
    shop_id: Number,
    counter: Number
  },
  setup(props) {
    const pdfData = ref([]);
    const pdfStats = ref([]);
    const billingData = ref([]);
    const shopData = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const totalBalance = ref(0);
    const totalCurrentBill = ref(0);
    const dayName = ref('');

    const urduText = (inputUrdu) => {
      let inputString = inputUrdu;
      //inputString = inputString.replace("(", "-");
      //inputString = inputString.replace(")", "");
      inputString = inputString.split(' ').reverse().join(' ');

      return inputString;
    };

    const loadPdf = async () => {


      await BillingServices.getDetailShopPdfContent(
        props.billing_id,
        props.shop_id
      )
        .then((response) => {
          pdfData.value = response.data.data;
          console.log(response.data.data);
          pdfStats.value = response.data.stats;
          billingData.value = response.data.billingData;
          shopData.value = response.data.shopData;
        })
        .then(() => {
          let columns = [];
          body.value = [];

          columns = [
            { text: 'Sr#', style: 'defaultFont', alignment: 'center' },
            { text: 'Product ', style: 'defaultFont', alignment: 'center' },
            { text: 'Info ', style: 'defaultFont', alignment: 'center' },
            { text: 'packing', style: 'defaultFont', alignment: 'center' },
            { text: 'Quantity', style: 'defaultFont', alignment: 'center' },
            { text: 'Price', style: 'defaultFont', alignment: 'center' },
            { text: 'Value', style: 'defaultFont', alignment: 'center' },
          ];
          body.value.push(columns);

          let i = 1;
          pdfData.value.forEach(function (row) {
            //if(i!=21){

            const dataRow = [];
            const seriol_no = {
              text: i,
              style: ['defaultFont', 'mtEnglish'],
              alignment: 'center',
            };
            dataRow.push(seriol_no);

            const product_urdu_name = {
              style: ['defaultFont', 'urduFont'],
              alignment: 'center',
            };
            const product_english_name = {
              style: ['defaultFont', 'mtEnglish'],
            };
            if (row['urdu_name'] != '' && row['urdu_name'] != null) {
              product_urdu_name['text'] = urduText(row['urdu_name']);
              dataRow.push(product_urdu_name);
            } else {
              product_english_name['text'] = row['name'];
              dataRow.push(product_english_name);
            }
  
            const product_extra_info = {
              style: ['defaultFont', 'mtEnglish'],
            };
            if (row['extra_info'] != null) {
              product_extra_info['text'] = row['extra_info'];
              //product_name['text']="(10)";
            } else {
              product_extra_info['text'] = '';
            }

            dataRow.push(product_extra_info);

            const packing = {
              text: row['packing_type'].toString(),
              style: ['defaultFont', 'mtEnglish'],
              alignment: 'center',
            };

            dataRow.push(packing);

            const quantity = {
              text: row['quantity'].toString(),
              style: ['defaultFont', 'mtEnglish'],
              alignment: 'center',
            };

            dataRow.push(quantity);

            const price = {
              text: eval(row['price'].toString()),
              style: ['defaultFont', 'mtEnglish'],
              alignment: 'center',
            };
            dataRow.push(price);
            const total = {
              text: row['total_amount'].toString(),
              style: ['defaultFont', 'mtEnglish'],
              alignment: 'center',
            };
            dataRow.push(total);
            body.value.push(dataRow);

            i++;
          });
        })
        .then(() => {
          //const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          //const  d = new Date(props.billing_date);
          //dayName.value = days[d.getDay()];
          pdfMake.fonts = {
            urdu: {
              normal:
                'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
              bold: 'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
              italics:
                'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
              bolditalics:
                'https://fonts.gstatic.com/ea/notonastaliqurdudraft/v4/NotoNastaliqUrduDraft.ttf',
            },
            Roboto: {
              normal:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
              bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
              italics:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
              bolditalics:
                'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
            },
          };
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: 'INVOICE #: ' + props.billing_id.toString(),
                    style: ['defaultFont', 'mb10'],
                  },
                  {
                    width: '50%',
                    text:
                      'DATE: ' +
                      moment(billingData.value.created_at).format('DD-MM-YYYY'),
                    style: ['defaultFont', 'textRight', 'mb10'],
                  },
                ],
              },

              {
                columns: [
                  {
                    width: '50%',
                    text:
                      'CUSTOMER: ' +
                      shopData.value.shop_name +
                      '(' +
                      shopData.value.id +
                      ')',
                    style: ['defaultFont', 'mb10'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '50%',
                    text:
                      'SALESMAN: ' +
                      billingData.value.first_name +
                      ' ' +
                      billingData.value.last_name +
                      '(' +
                      billingData.value.salesman_id +
                      ')',
                    style: ['defaultFont', 'mb2'],
                  },
                  {
                    width: '50%',
                    text: 'SECTOR/TOWN: ' + shopData.value.address,
                    style: ['defaultFont', 'textRight', 'mb2'],
                  },
                ],
              },
              {
                table: {
                  headerRows: 1,
                  dontBreakRows: true,
                  widths: [30, '*', '*', '*', '*', '*', '*'],
                  //margin: [ 5, 2, 10, 20 ],
                  body: body.value,
                },
              },

              {
                columns: [
                  {
                    width: '41%',
                    text: 'Discount: :',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '26%',
                    text: pdfStats.value.discount.toString(),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                  {
                    width: '13%',
                    text: 'Gross Value:',
                    style: ['defaultFont', 'mb15'],
                  },
                  {
                    width: '23%',
                    text:
                      eval(pdfStats.value.grossValue) +
                      eval(pdfStats.value.discount),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '78%',
                    text: 'Net Value: ',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '27%',
                    text: pdfStats.value.netValue.toString(),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '80%',
                    text: 'Prev Balance:',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '23%',
                    text: pdfStats.value.previousBalance.toString(),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '78%',
                    text: 'Received:',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '27%',
                    text: pdfStats.value.received.toString(),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: '80%',
                    text: 'New Balance:',
                    style: ['defaultFont', 'textRight', 'mb15'],
                  },
                  {
                    width: '23%',
                    text: pdfStats.value.newBalance.toString(),
                    style: ['defaultFont', 'textCenter', 'mb15'],
                  },
                ],
              },
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mt2: {
                margin: [0, 2, 0, 0],
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mb10: {
                margin: [0, 0, 0, 10],
              },
              mb15: {
                margin: [0, 3, 0, 2],
              },
              mCustom: {
                margin: [20, 2, 0, 0],
              },
              urduFont: {
                font: 'urdu',
              },
              mtEnglish: {
                margin: [0, 10, 0, 0],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
        });
    };
    watch(
      () => props.counter,
      (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != '') {
          loadPdf();
        }
      }
    );

    return {
      pdfData,
      pdfStats,
      billingData,
      shopData,
      documentDefinition,
      body,
      totalBalance,
      totalCurrentBill,
      dayName,
      urduText,
    };
  },
};
</script>
