<template>
    <!-- Update Modal -->
<div class="modal fade p-0" id="editShop" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg">
		<div class="d-flex flex-wrap w-100 position-relative event-auto">
			<div class="card shadow-none">
			<Form method="post" class="custom-form mt-2 assign-shops" @keydown="errors_v.clear($event.target.name)" @submit="doSubmit" :validation-schema="schema" v-slot={errors}>
				<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
					<div class="content-card-left w-100">
						<h4 class="card-title text-white fs-4">Update Assigned Shops</h4>
						<p class="fw-light text-white mb-0 pt-1">Update assigned shops to Salesman</p>
					</div>
				</div>

				<div class="card-body"  >
					
						<div class="row mx-0" v-for="(shop, key) in shops" :key="shop.id" >
							
							<div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
								
								<Multiselect
									v-model="shop.shop_id"
									:options="modalOptions"
									class="custom-multiselect"
									placeholder="Shop"
									:searchable="true"
									:showOptions="true"
									mode="single"
									label="shop_name"
									trackBy	= "shop_name"
									valueProp="id"
   									required: true
									
									
									
								/>
								<Field type="hidden" :name="'shop_name[' + key + ']'"  v-model="shop.shop_id" />
								
								<p v-if="errors_v.get('shopsData.'+key+'.shop_id') && (shop.shop_id==null || shop.shop_id=='')" class="invalid-message mb-0 pt-2">
										{{errors_v.get('shopsData.'+key+'.shop_id')}}
								</p>
								<p v-if="errors['shop_name['+key+']']" class="invalid-message mb-0 pt-2">
									{{ errors['shop_name['+key+']'] }}
								</p>
							</div>
							<div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
								<div class="position-relative">
									<Field type="number" :name="'initial_balance[' + key + ']'" :class="[balance=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.initial_balance" />
									<label for="" class="mb-0 placeholder-label w-auto">Balance</label>
									<p v-if="errors_v.get('shopsData.'+key+'.initial_balance') && (shop.initial_balance==null || shop.initial_balance=='')" class="invalid-message mb-0 pt-2">
										{{errors_v.get('shopsData.'+key+'.initial_balance')}}
									</p>
									<p v-if="errors['initial_balance['+key+']']" class="invalid-message mb-0 pt-2">
										{{ errors['initial_balance['+key+']'] }}
									</p>
									
									
								</div>
							</div>
						</div>
				</div>
				<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
					<button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Edit Company
                </button>
				</div>
			</Form>
			</div>
		</div>
	</div>			
</div>
</template>
<script>
import {ref,reactive, watch} from 'vue';
import Multiselect from '@vueform/multiselect';
import salesmanShopServices from '@/services/SalesmanShopServices';
import shopServices from '@/services/ShopServices';
import "@vueform/multiselect/themes/default.css";
import SalesmanShopServices from '@/services/SalesmanShopServices';
import Errors from "@/utils/Errors";
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
export default {
    components:{
        Multiselect,
		Form,
		Field
	
		
    },
    props:{
        salesman_id: Number,
        day: String
    },
    setup(props,{emit}){
		
		//const initial_balanceError=ref("");
        const loader=ref(false);
        const searchDisabled=ref(false);
	    const modalOptions = ref("");
        const shops=ref([]);
        async function get_all_shops(){
			await shopServices.getAllShops()
            .then(response=>{
                 modalOptions.value= response.data;
				 console.log(modalOptions);
            })
		}

		async function get_selected_shops(){
			await salesmanShopServices.getOneDaySalesmanShop(props.salesman_id,props.day)
              .then(response=>{
                shops.value=response.data.data;
              })
		}
        watch(()=>props.day, (currentValue, oldValue) => {
          if(currentValue!=oldValue && currentValue!="" && props.salesman_id>0){
            if(modalOptions.value===""){
				get_all_shops();
			}
			
			get_selected_shops();
			
              
          }
        });
		
		const errors_v = reactive(new Errors());
		const closeModal= ()=>{
            const myModalEl = document.getElementById('editShop');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
          }
		const schema = Yup.object().shape({
            initial_balance: Yup.array().of(
            Yup.number()
                .typeError("Only Numbers Allowed"),
			),
			shop_name: Yup.array().of(
            Yup.string()
                .required('Shop Name is required').nullable()
			)
        });
        
		const doSubmit = async(values, actions) => {
			loader.value=true;
          	searchDisabled.value=true;
			const payload={
				"shopsData": shops.value
			}

			await SalesmanShopServices.updateSalesmanShops(payload,props.salesman_id)
              .then(async response=>{
                
                closeModal();
                loader.value=false;
                searchDisabled.value=false;
                emit('alertEvent', 'edit');
              })
              .catch(error => {
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
					alert("error");
					errors_v.record(error.response.data.errors);
                }
              });
		
    	};
		
        return {
            modalOptions,
            shops,
			
			doSubmit,
			loader,
			searchDisabled,
			closeModal,
			errors_v,
			schema,
			get_all_shops,
			get_selected_shops
			
			
        }
    }
}
</script>