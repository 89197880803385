import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

declare global {
  interface Window {
    bootstrap: any;
  }
}

// Adding bootstrap

import './assets/scss/_custom_bootstrap.scss';
// importing Bootstrap Icons
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

createApp(App).use(store).use(router).use(VCalendar, {}).use(CKEditor).mount('#app');
