<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import balanceSheetServices from '@/services/BalanceSheetServices';
import moment from 'moment';
export default {
  props: {
    counter_all: Number,
    date: String,
  },
  setup(props) {
    const dailyData = ref([]);
    const documentDefinition = ref({});
    const body = ref([]);
    const loadPdf= async()=>{
                await balanceSheetServices.getDailyCheck(moment(String(props.date)).format('YYYY-MM-DD'))
    
                .then(response=>{
                    dailyData.value= response.data.data;
                })
        .then(() => {
          const columns = [
            { text: 'id', style: 'defaultFont', alignment: 'center' },
            { text: 'Name ', style: 'defaultFont', alignment: 'center' },
            { text: 'Debit', style: 'defaultFont', alignment: 'center' },
            { text: 'Credit', style: 'defaultFont', alignment: 'center' },
            { text: 'Return', style: 'defaultFont', alignment: 'center' },
          ];
          body.value.push(columns);
          let i = 1;
          dailyData.value.forEach(function (row) {
            const dataRow = [];
            const id = {
              text: i.toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(id);
    

            const name = {
              style: 'defaultFont',
              alignment: 'center',
            };

            if (row['first_name'] != '') {
              name['text'] = row['first_name']+' '+row['last_name'];
            } else {
              name['text'] = '';
            }
            dataRow.push(name);

            const debit = {
              text: row['debit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(debit);

            const credit = {
              text: row['credit'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };

            dataRow.push(credit);

            const back = {
              text: row['return'].toString(),
              style: 'defaultFont',
              alignment: 'center',
            };
            dataRow.push(back);
            i++;
            body.value.push(dataRow);
          });
        })
        .then(() => {
          documentDefinition.value = {
            content: [
              {
                columns: [
                  {
                    width: '50%',
                    text: 'All Salesman Daily Report:',
                    style: ['defaultFont', 'mb2'],
                  },
                ],
                
              },
        {
            columns: [
                {
                width: '38%',
                text: 'Date:'+moment(String(props.date)).format('YYYY-MM-DD'),
                style: [ 'defaultFont', 'textLeft', 'mb15']
                },
            ],
        },
              {
                table: {
                  headerRows: 1,
                  widths: [30, '*', '*', '*','*'],
                  body: body.value,
                },
              },
              {},
            ],
            styles: {
              defaultFont: {
                fontSize: 11,
                bold: true,
              },
              textRight: {
                alignment: 'right',
              },
              textCenter: {
                alignment: 'center',
              },
              mb2: {
                margin: [0, 0, 0, 2],
              },
              mt2: {
                margin: [0, 4, 0, 0],
              },
            },
          };
        })
        .then(() => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition.value).open();
          body.value = [];
        });
    };
    watch(
      () => props.counter_all,
      (currentValue, oldValue) => {
        if (currentValue != oldValue) {
          loadPdf();
        }
      }

    );
    return {
      dailyData,
      documentDefinition,
      body,
      loadPdf,      
    };
  },
};
</script>
