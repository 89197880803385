
<script>
import { watch, ref } from 'vue';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import CompanyServices from '@/services/CompanyServices';
import moment from 'moment';
export default {
    props:{
        company_id: Number,
        count_number: Number,
        company_name: String,
    },
    setup(props){
        
        const pdfData=ref([]);

        const documentDefinition=ref({});
        const body=ref([]);
        const total_quantity=ref(0);
        const total_amount=ref(0);

        const loadPdf=async ()=>{
            await CompanyServices.getOneCompanyProductsPdf(props.company_id)
      			.then(response=>{
                    pdfData.value=response.data;

                    
      			})
                .then(() => {
                    let columns=[];
                    body.value=[];
                    
                    columns=[
                        {text: 'No', style: 'defaultFont', alignment: 'center'},
                        {text: 'Name', style: 'defaultFont', alignment: 'center'},
                        {text: 'Per unit price', style: 'defaultFont', alignment: 'center'},
                        {text: 'Cartons', style: 'defaultFont', alignment: 'center'},
                        {text: 'Boxes', style: 'defaultFont', alignment: 'center'},
                        {text: 'Total Amount', style: 'defaultFont', alignment: 'center'}
                    ];
                    body.value.push(columns);
                    
                    let i=1;
                    total_amount.value=0;
                    pdfData.value.forEach(function(row) {
                                               
                        const dataRow=[];
                        const seriol_no={
                            text: i,
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center',
                            
                        };
                        dataRow.push(seriol_no);

                        const product_english_name={
                            style: ['defaultFont', 'mtEnglish'],
                            text: row['name']
                            
                        }
                        dataRow.push(product_english_name);
                        
                        const cartons={
                            text: row['cartons'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        const initial_price_per_unit={
                            text: row['initial_price_per_unit'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        dataRow.push(initial_price_per_unit);
                        dataRow.push(cartons);
                        
                        const boxes={
                            text: row['boxes'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center'
                        };
                        
                        dataRow.push(boxes);

                        const total={
                            text: row['total_amount'].toString(),
                            style: ['defaultFont', 'mtEnglish'],
                            alignment: 'center', 
                            
                        }
                        dataRow.push(total);
                        total_amount.value =eval(total_amount.value)+eval(row['total_amount']);
                        body.value.push(dataRow);
                        i++;
                    });
                   
                })
                .then(() => {
                    pdfMake.fonts = {
					
					Roboto: {
						normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
						bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
						italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
						bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
					},
				}
                    documentDefinition.value = {
                        content: [
                            
                            {
                                columns: [
                                    {
                                    width: '50%',
                                    text: 'Company: '+props.company_name,
                                    style: [ 'defaultFont', 'mb10' ]

                                    },
                                    {
                                    width: '50%',
                                    text: 'DATE: '+moment().format('DD-MM-YYYY'),
                                    style: [ 'defaultFont', 'textRight', 'mb10' ]
                                    },
                                ],
                                
                            },
                            {
                                
                                table: {
                                    headerRows: 1,
                                     dontBreakRows: true, 
                                    widths: [30, '*','*','*' , '*','*'],
                                    body: body.value
                                }
                            },
                            
                            {
                                columns: [
                                    {
                                    width: '38%',
                                    text: 'Total Amount:',
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    },
                                    {
                                    width: '101%',
                                    text: total_amount.value.toFixed(2),
                                    style: [ 'defaultFont', 'textCenter', 'mb15']
                                    },
                                ],
                            },
                        ],
                        styles: {
                            defaultFont: {
                            fontSize: 11,
                            bold: true
                            },
                            textRight: {
                            alignment: 'right'
                            },
                            textCenter: {
                            alignment: 'center'
                            },
                            mt2: {
                            margin:[0,2,0,0]
                            },
                            mb2: {
                            margin:[0,0,0,2]
                            },
                            mb10: {
                            margin:[0,0,0,10]
                            },
                            mb15: {
                            margin:[0,3,0,2]
                            },
                            mCustom: {
                            margin:[20,2,0,0]
                            },
                            
                            mtEnglish: {
                                margin:[0,10,0,0]
                            },
                        }
                        };
                })
                .then(() => {
                    
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(documentDefinition.value).open();
                });

                
        }
        watch( ()=>props.count_number, (currentValue, oldValue) => {
			if(currentValue!=oldValue && currentValue!=""){
                loadPdf();
			}
        });
        
        return {
            pdfData,
            documentDefinition,
            body,
            total_quantity,
            total_amount
        }
    }
}
</script>
