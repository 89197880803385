<template>
	<!-- Inner content -->
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
                <div class="content-card-left">
                  <h4 class="card-title text-white">Returns</h4>
                  <p class="card-category mb-0">Here you can manage Returns</p>
                </div>
                <div class="content-card-right ms-auto">
                  
                  <router-link to="/return/add"  class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </router-link>
                  <a
                    href="#"
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle me-1"
                    @click="counter_all++"
                  >
                    <img
                      src="@/assets/images/pdf.png"
                      alt="pdf-image"
                      class="small-image white-image"
                    />
                  </a>
                  
                </div>
              </div>
              <div class="card-body" >
                <div class="header-content">
                                        <form class="table-header mx-0 mb-4 custom-form">
                    <div class="row m-0 align-items-center">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-12 px-0">
                        <h4 class="filter mb-lg-0 mb-md-0 mb-sm-0 mb-3">
                            <i class="bi bi-funnel me-1 position-relative"></i>Filters
                        </h4>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-9 col-12 px-0 ms-auto">
                        <v-date-picker class="custom-calendar left-caret-none" popover-align="right" :step="1" v-model="range" is-range :columns="$screens({ default: 2, lg: 2 })">
                          <template v-slot="{ inputValue, inputEvents }">
                              <div class="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-start">
                              <div class="position-relative calendar-input-wrapper clearfix">
                                <span class="d-inline-block me-4 float-left">From</span>
                                <i class="bi bi-calendar3 position-absolute icon-left-placement"></i>
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  class="border-0 icon-left position-relative float-left"
                                />
                              </div>
                              <div class="position-relative calendar-input-wrapper clearfix">
                                <span class="d-inline-block ms-lg-4 ms-md-4 ms-sm-4 ms-0 me-lg-0 me-md-0 me-sm-0 me-4 float-left">To</span>
                                <i class="bi bi-calendar3 position-absolute icon-left-placement"></i>
                                <input
                                :value="inputValue.end"
                                v-on="inputEvents.end"
                                class="border-0 icon-left position-relative float-left"
                                />
                              </div>
                                </div>
                            </template>
                        </v-date-picker>
                      </div>
                    </div>
                  </form>
                  <div class="table-responsive mb-0">
                    <table id="my-table"  class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Id</th>
                          <th class="text-capitalize">Date</th>
                          <th class="text-capitalize">Salesman</th>
                          <th class="text-capitalize">Total Items</th>
                          <th class="text-capitalize">Total Adjustments</th>
                          <th class="text-capitalize text-center">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="returnItem in returns" :key="returnItem.id">
                          <td><a href="#">{{returnItem.id}}</a></td>
                          <td>{{formatDate(returnItem.created_at)}}</td>
                          <td>{{returnItem.first_name}} {{returnItem.last_name}}</td>
                          <td>{{returnItem.total_items}}</td>
                          <td>{{returnItem.total_amount}}</td>
                          
                          <td class="action text-center">
                            <a href="#" class="edit d-inline-block" data-bs-toggle="modal" data-bs-target="#billingDetail" @click="view_id = returnItem.id">
                              <i class="bi bi-eye-fill"></i>
                            </a>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                <p class="d-lg-inline-block table-data">
                  Showing
                  {{ current_page * entries_per_page - (entries_per_page - 1) }}
                  to
                  {{
                    current_page * entries_per_page -
                    (entries_per_page - returns.length)
                  }}
                  of {{ total_count }} entries.
                </p>
                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper">
                  <v-pagination class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                    v-model="current_page"
                    :pages="total_pages"
                    :range-size="3"
                    @update:modelValue="loadData"
                  />
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
    <return-detail :return_id="view_id"></return-detail>
  </div>
  <return-pdf :counter_all="counter_all" :start_date="range.start" :end_date="range.end"></return-pdf>
  
</template>
<script>
  import { ref, onMounted, watch } from 'vue';
  import "@/assets/scss/pages/_billing.scss";
  import returnServices from '@/services/ReturnServices';
  import VPagination from "@hennge/vue3-pagination";
  import moment from 'moment'
  import ReturnDetail from '@/components/modals/ReturnViewModal.vue';
  //import BillingMainPdf from '@/components/pdf/BillingMainPdf.vue';
  import ReturnPdf from '@/components/pdf/ReturnPdf.vue';
	export default {
    components:{
      VPagination,
      ReturnDetail,
      //BillingMainPdf
      ReturnPdf,
    },
		setup() {
      const total_count=ref(0);
      const entries_per_page=ref(0);
			const returns=ref([]);
      const total_pages= ref(0);
      const current_page= ref(1);
      const startDate=ref("");
      const endDate=ref("");
      const view_id=ref(0);
      const salesman_id=ref(0);
      const billing_pdf_id=ref(0);
      const salesman_name=ref("");
      const billing_date=ref("");
      const counter_all = ref(0);
      const range = ref({
				start: moment().format('MM-DD-YYYY'),
        end: new Date(new Date().getFullYear(), new Date().getMonth()
, 1),
			});  
      const loadData= async()=>{
        
        await returnServices.paginateReturns(current_page.value, startDate.value, endDate.value)
        .then(response=>{
          returns.value= response.data.data;
         
          total_pages.value=response.data.last_page;
          current_page.value=response.data.current_page;
          entries_per_page.value=response.data.per_page;
          total_count.value=response.data.total;
        })
        .catch(error => {  
					alert("error");
        });
      }
        onMounted( () => {
        loadData();
      });

      const updatePdfIds=(billing)=>{
        billing_pdf_id.value=billing.id;
        salesman_id.value=billing.salesman_id;
        salesman_name.value=billing.first_name+" "+billing.last_name;
        billing_date.value=billing.created_at;
      }
      			
      watch(async()=>range.value, (currentValue, oldValue) => {
          if(currentValue!=oldValue && currentValue!=""){
            startDate.value=moment(String(range.value.start)).format('YYYY-MM-DD');
            endDate.value=moment(String(range.value.end)).format('YYYY-MM-DD');
            loadData();  
          }
      });
      const formatDate = (value)=>{
         if (value) {
           return moment(String(value)).format('MMM Do YY');
          }
      }

			return {
				
				range,
        loadData,
        returns,
        total_pages,
        current_page,
        formatDate,
        startDate,
        endDate,
        view_id,
        salesman_id,
        salesman_name,
        billing_pdf_id,
        updatePdfIds,
        billing_date,
        entries_per_page,
        total_count,
        counter_all
			}
		}
	}
</script>