<template>
    <!-- Add Modal -->
<div class="modal fade p-0" id="addInventory" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg">
		<div class="d-flex flex-wrap w-100 position-relative event-auto">
			<div class="card shadow-none">
				<Form @submit="onSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" class="custom-form mt-2 assign-shops" ref="myForm">
                    <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
                        <div class="content-card-left w-100">
                            <h4 class="card-title text-white fs-4">Inventory In</h4>
                            <p class="fw-light text-white mb-0 pt-1">Add Products To Inventory</p>
                        </div>
                    </div>
                    <div class="card-body" >

                            <div class="row mx-0">
                                <div class="col-12 mb-4">
                                    <div class="position-relative form-group">
                                        <Field as="select" name="company_id" class="form-select custom-select" @click="selectFocus = true" v-model="company_model">
                                            <option value="0">Choose Company</option>
                                            <option v-for="company in companies" :value="company.id" :key="company.id">{{company.name}}</option>
                                        </Field>
                                        <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Company</label>
                                        <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                        
                                        <ErrorMessage class="invalid-message mb-0 pt-2" name="company_id" />
                                        <p v-if="errors_v.get('companyId') && (company_model==null || company_model=='')" class="invalid-message mb-0 pt-2">
											{{errors_v.get('companyId')}}
										</p>
                                    </div>
                                </div>
                                <fieldset class="InputGroup row mx-0" v-for="(user, idx) in users" :key="user.id">
                                    <div class="col-12" v-if="idx==0">
                                        <div class="d-flex flex-wrap aligm-items-center mb-3">
                                            <h6 class="mb-0 text-primary">Products</h6>
                                            <div class="text-center align-self-center add-rows-wrapper ms-auto">
                                                <span class="d-inline-block add bi bi-plus-circle-fill text-primary" @click="add"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-else>
                                        <div class="d-flex flex-wrap aligm-items-center mb-3">
                                            <h6 class="mb-0 text-primary">Products</h6>
                                            <div class="text-center align-self-center add-rows-wrapper ms-auto">
                                                <span class="d-inline-block add bi bi-dash-circle-fill text-primary" @click="remove(user)"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
                                        <Multiselect @select="changeMultiselect(user.product_model,idx)"
											v-model="user.product_model"
											:options="modalOptions"
											class="custom-multiselect"
											placeholder="Products"
											:searchable="true"
											:showOptions="true"
											mode="single"
											label="name"
											trackBy	= "name"
											valueProp="id"
											required: true
											:id="`multiselect_${idx}`"
											:name="`users[${idx}].multiselect`"
										/>
										
										<Field type="hidden" :id="`productid_${idx}`" :name="`users[${idx}].productid`" v-model="values_hidden[idx]" />
                                        <ErrorMessage class="invalid-message mb-0 pt-2" :name="`users[${idx}].productid`" />
                                        <p v-if="errors_v.get('inventoryData.'+idx+'.productid') && (values_hidden[idx]==null || values_hidden[idx]=='')" class="invalid-message mb-0 pt-2">
											{{errors_v.get('inventoryData.'+idx+'.productid')}}
										</p>
                                        
                                    </div>
                                    <div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
                                        <div class="position-relative form-group">
                                            <Field as="select" :id="`ptype_${idx}`" :name="`users[${idx}].ptype`" class="form-select custom-select" @click="selectFocus = true" v-model="user.ptype_model">
                                                <option value="cartons">Cartons</option>
                                                <option value="boxes">Boxes</option>
                                            </Field>
                                            <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Quantity In</label>
                                            <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
                                        <div class="position-relative">
                                            <Field as="input" :id="`quantity_${idx}`" :name="`users[${idx}].quantity`" type="number" :class="[user.quantity_model=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="user.quantity_model"/>
                                            <label for="" class="mb-0 placeholder-label w-auto">Quantity</label>
                                            <p v-if="errors_v.get('inventoryData.'+idx+'.quantity') && (user.quantity_model==null || user.quantity_model=='')"  class="invalid-message mb-0 pt-2">
												{{errors_v.get('inventoryData.'+idx+'.quantity')}}
											</p>
                                            <ErrorMessage class="invalid-message mb-0 pt-2" :name="`users[${idx}].quantity`" />
                                        </div>
                                    </div>
                                </fieldset>
                                
                            </div>
                            

                    </div>
                    <div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
                        <button class="btn btn-primary w-100" :disabled="searchDisabled">
                            <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Inventory
                        </button>
                    </div>
                </Form>
			</div>
		</div>
	</div>			
</div>
</template>
<script>
import Multiselect from '@vueform/multiselect';
import {Form, Field, ErrorMessage, ErrorBag} from 'vee-validate';
import {ref, reactive, onMounted, watch} from 'vue';
import CompanyServices from '@/services/CompanyServices';
import ProductServices from '@/services/ProductServices';
import * as yup from "yup";
import InventoryServices from '@/services/InventoryServices';
import Errors from "@/utils/Errors";
export default {
    components:{
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    setup(props,{emit}){
        const companies=ref([]);
        const company_model=ref(0);
        const modalOptions = ref("");
        const loader=ref(false);
        const searchDisabled=ref(false);
        const users=ref([{
            id: Date.now(),
            product_model: "",
            ptype_model: 'cartons',
            quantity_model: ""
        }]);
        const values_hidden=ref({});
        const errors_v = reactive(new Errors());
        

        const schema = yup.object().shape({
            company_id: yup.number().moreThan(0, 'Company Name Required'),
            users: yup
                .array()
                .of(
                yup.object().shape({
                    productid: yup.number().required().label("Product Name"),
                    quantity: yup.number().moreThan(0, 'Product Quantity Required').typeError("Only Numbers Allowed").label("Quantity"),
                })
                )
					
		});
        const add= ()=>{
            
            users.value.push({
                id: Date.now(),
                product_model: "",
                ptype_model: 'cartons',
                quantity_model: ""
            });
		}

        const remove=(item)=>{
            const index = users.value.indexOf(item);
            if (index === -1){
                return;
            }
            users.value.splice(index, 1);
        }
        onMounted(async () => {
            await CompanyServices.paginateCompanies()
            .then(response=>{
                companies.value= response.data.data;
                console.log(companies.value);
            })
		});
        function resetCustomForm() {
            users.value.length=0;
            users.value=[{
                id: Date.now(),
                product_model: "",
                ptype_model: 'cartons',
                quantity_model: ""
            }]
            values_hidden.value={};
        }
        watch(()=>company_model.value, (currentValue, oldValue) => {
          if(currentValue!=oldValue && currentValue!=""){
			ProductServices.CompanyProducts(company_model.value)
            .then(response=>{
                 modalOptions.value= response.data;
				 console.log(modalOptions.value);
            })
            resetCustomForm(); 
          }
        });
        function changeMultiselect(value,idx){
			values_hidden.value[idx]=value;
		}
        const closeModal= ()=>{
            const myModalEl = document.getElementById('addInventory');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
        }
        

        const onSubmit = async(values, actions) => {
            loader.value=true;
            searchDisabled.value=true;
			//alert(errors);
			//alert(JSON.stringify(errors, null, 2));
            let payload={
                "inventoryData": values.users,
                "companyId": values.company_id
			}
            
            if(company_model.value===0){
                 payload={
                    "inventoryData": values.users,
                    "companyId": ""
				}
            
            }
            
           
            await InventoryServices.addInventories(payload)
            .then(async response=>{
                closeModal();
                actions.resetForm();
                resetCustomForm();
                loader.value=false;
                searchDisabled.value=false;
                emit('alertEvent', 'add');
            })
            .catch(error => {
                loader.value=false;
                searchDisabled.value=false;
                if(error.response.status==422){
                    errors_v.record(error.response.data.errors);
                }
            });
				
			}
        return {
            users,
            add,
            remove,
            companies,
            company_model,
            modalOptions,
            changeMultiselect,
            values_hidden,
            onSubmit,
            schema,
            errors_v,
            closeModal,
            loader,
            searchDisabled,
            resetCustomForm

        }
    }
}
</script>