<template>
  <!-- modal start -->
  <!-- Edit Modal -->
  <div>
    <div
      class="modal fade p-0"
      id="edit"
      ref="editModal"
      tabindex="-1"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div
              class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
            >
              <div class="content-card-left w-100">
                <h4 class="card-title text-white">Edit Topic</h4>
                <p class="card-category mb-0">
                  Here you can Edit Content & Questions
                </p>
              </div>
            </div>
            <div class="card-body">
              <Form
                id="editTopicModelForm"
                class="custom-form"
                @submit="doSubmit"
                @keydown="errors_v.clear($event.target.name)"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <h3 class="font-18 fw-semibold mb-3">Topic Section</h3>
                <div class="col-12">
                  <Field
                    name="editerContent"
                    v-model="form.editorContent"
                    v-slot="{ field }"
                  >
                    <ckeditor
                      v-bind="field"
                      class="custom-ckeditor"
                      :editor="editor"
                      v-model="form.editorContent"
                    ></ckeditor>
                  </Field>
                  <ErrorMessage
                    class="invalid-message mb-0 pt-2"
                    name="editerContent"
                  />
                </div>
                <div class="col-12 mt-4">
                  <div class="position-relative">
                    <Field
                      type="text"
                      id="query"
                      name="query"
                      :class="[
                        form.query == '' ? '' : 'active',
                        'border-0 input-btn-right position-relative w-100 pe-5 mt-0',
                      ]"
                      v-model="form.query"
                    />
                    <label for="" class="mb-0 placeholder-label">Query</label>
                    <p v-if="errors.query" class="invalid-message mb-0 pt-2">
                      {{ errors.query }}
                    </p>
                    <p
                      v-if="errors_v.get('query')"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors_v.get('query') }}
                    </p>
                    <button
                      :disabled="form.editorContent ? false : true"
                      type="button"
                      @click.prevent="
                        rewriteContent(form.query, form.editorContent, 'topic')
                      "
                      class="btn btn-dark z-5 btn-sm-font position-absolute top-20 translate-middle-y end-0 py-1 px-2 me-1"
                    >
                      <div
                        id="topic"
                        class="d-none spinner-border me-2"
                        role="status"
                      ></div>
                      Rewrite Topic
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-4 mb-3">
                  <div class="position-relative">
                    <Field
                      name="content"
                      :class="[
                        form.content == '' ? '' : 'active',
                        'form-control border-0 position-relative w-100 py-2 mt-0',
                      ]"
                      :textContent="form.content"
                      v-model="form.content"
                      as="textarea"
                    />
                    <label for="" class="mb-1 placeholder-label"
                      >Topic Content</label
                    >
                    <p v-if="errors.content" class="invalid-message mb-0 pt-2">
                      {{ errors.content }}
                    </p>
                    <p
                      v-if="errors_v.get('content')"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors_v.get('content') }}
                    </p>
                  </div>
                </div>
                <div class="col-12 text-end modal-footer border-0 mt-md-0 mt-4">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="openPreviewModal"
                  >
                    Preview Topic
                  </button>
                  <button type="submit" class="btn btn-primary">
                    <div
                      id="topicSave"
                      class="d-none spinner-border me-2"
                      role="status"
                    ></div>
                    Save Topic
                  </button>
                </div>
              </Form>

              <Form
                class="custom-form"
                @submit="submitTopicQuestion"
                @keydown="errors_v.clear($event.target.name)"
                :validation-schema="questionFormSchema"
                v-slot="{ errors }"
              >
                <div class="col-12 border-2 border-dark border-top pt-3">
                  <h3 class="font-18 fw-semibold mb-3">Question Section</h3>
                  <button
                    v-if="!replaceQuestionActive"
                    @click.prevent="cpTopicQuestion('copy', topicId)"
                    type="button"
                    class="font-14 border-0 bg-transparent"
                  >
                    {{ 'Copy' }} <i class="bi bi-clipboard"></i>
                  </button>
                  <button
                    v-if="replaceQuestionActive"
                    @click.prevent="cpTopicQuestion('remove', topicId)"
                    type="button"
                    class="font-14 border-0 bg-transparent"
                  >
                    {{ 'Undo' }} <i class="bi bi-clipboard"></i>
                  </button>
                  <button
                    v-if="
                      replaceQuestionActive && topicId != replaceQuestionActive || replaceSingleQuestionActive && topicId != oldTopicId
                    "
                    type="button"
                    class="font-14 border-0 bg-transparent ms-4"
                    @click.prevent="replaceQuestionAnswers()"
                  >
                    Paste
                    <img
                      src="@/assets/images/paste.png"
                      class="w-16 contain"
                      alt=""
                    />
                  </button>
                </div>

                <div class="col-12 mt-4">
                  <div class="position-relative">
                    <Field
                      type="text"
                      id="questionQuery"
                      name="questionQuery"
                      :class="[
                        questionForm.questionQuery == '' ? '' : 'active',
                        'border-0 input-btn-lg-right position-relative w-100 pe-5 mt-0',
                      ]"
                      v-model="questionForm.questionQuery"
                    />
                    <label for="" class="mb-0 placeholder-label">Query</label>
                    <p
                      v-if="errors.questionQuery"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors.questionQuery }}
                    </p>
                    <p
                      v-if="errors_v.get('questionQuery')"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors_v.get('questionQuery') }}
                    </p>
                    <button
                      @click.prevent="
                        rewriteContent(
                          questionForm.questionQuery,
                          questionsAndAnswers,
                          'question'
                        )
                      "
                      type="button"
                      class="btn btn-dark z-5 btn-sm-font position-absolute top-20 translate-middle-y end-0 py-1 px-2 me-1"
                    >
                      <div
                        id="question"
                        class="d-none spinner-border me-2"
                        role="status"
                      ></div>
                      Rewrite Questions
                    </button>
                  </div>
                </div>

                <div class="col-12 mt-4 mb-3">
                  <div class="position-relative">
                    <Field
                      name="questionContent"
                      :class="[
                        questionForm.questionContent == '' ? '' : 'active',
                        'form-control border-0 position-relative w-100 py-2 mt-0',
                      ]"
                      v-model="questionForm.questionContent"
                      placeholder="Question 1: what is ChatGPT? 
Answer 1:Api
Answer 2: Model. 
Answer 3: Feature. 
Answer 4: All of the above (correct)."
                      as="textarea"
                    />
                    <!-- <label for="" class="mb-1 placeholder-label"
                    >Question Content</label
                  > -->
                    <p
                      v-if="errors.questionContent"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors.questionContent }}
                    </p>
                    <p
                      v-if="errors_v.get('questionContent')"
                      class="invalid-message mb-0 pt-2"
                    >
                      {{ errors_v.get('questionContent') }}
                    </p>
                  </div>
                </div>

                <div
                  v-for="(question, idx) in questionForm.topicQuestion"
                  :key="idx"
                  class="col-12 mt-4"
                >
                  <p class="font-14 fw-semibold d-flex align-items-center mb-0">
                    {{ 'Question:' + (idx + 1) }}
                    <i
                      @click="showConfirmDeleteModal(question.id)"
                      class="bi bi-trash3-fill text-danger fs-6 pointer ms-2"
                    ></i>
                    <i
                      v-if="replaceSingleQuestionActive!=question.id"
                      @click="cpSingleTopicQuestion('copy', question.id, question.top),copyQuestionToClipboard(idx)"
                      class="bi bi-clipboard fs-6 pointer ms-2"
                    ></i>
                    <i
                     v-else
                      @click="cpSingleTopicQuestion('remove', question.id, question.top)"
                      class="bi bi-clipboard-x fs-6 pointer ms-2"
                      
                    ></i>
                  </p>
                  <p class="font-14 fw-semibold mb-1">
                    {{ question?.question }}
                  </p>
                  <p class="font-14 mb-0">
                    <span class="fw-semibold">Question Type: </span
                    >{{ question.question_type }}
                  </p>
                  <ul>
                    <li
                      class="font-14"
                      v-for="(answer, ansIdx) in question.answers"
                      :key="ansIdx"
                    >
                      {{ answer.answer }}
                    </li>
                  </ul>
                </div>

                <div class="col-12 text-end modal-footer mt-md-0 mt-4">
                  <button type="submit" class="btn btn-primary">
                    <div
                      id="topicquestion"
                      class="d-none spinner-border me-2"
                      role="status"
                    ></div>
                    Save Questions
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal end -->
    <div
      class="modal fade p-0"
      ref="previewModal"
      id="previewEditor"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div
              class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
            >
              <div class="content-card-left w-100">
                <h4 class="card-title text-white">Preview Modal</h4>
                <p class="card-category mb-0">Topic Content Modal</p>
              </div>
            </div>
            <div class="card-body">
              <p><span v-html="form.content"></span></p>
              <div class="col-12 text-end modal-footer mt-md-0 mt-4">
                <button
                  @click="closePreviewModal"
                  type="button"
                  class="btn btn-secondary"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  delete confirmation modal -->
    <div
      class="modal fade p-0"
      ref="confirmDeleteModal"
      id="deleteConfirmation"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="d-flex flex-wrap w-100 position-relative event-auto">
          <div class="card shadow-none">
            <div class="card-body p-0">
              <div class="row text-center py-4 px-2 mx-0">
                <div class="mb-2">
                  <i
                    class="bi bi-exclamation-triangle text-danger fs-1 lh-1"
                  ></i>
                </div>
                <h4 class="mb-2">Are you sure?</h4>
                <h6 class="mb-0 text-secondary">
                  You won't be able to revert this!
                </h6>
              </div>
              <div class="col-12 py-2 modal-footer">
                <button
                  @click="hideConfirmDeleteModal(false)"
                  type="button"
                  class="btn btn-secondary py-1"
                >
                  Cancel
                </button>
                <button
                  @click="hideConfirmDeleteModal(true)"
                  class="btn btn-danger"
                >
                  Yes , Delete <i class="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed } from 'vue';
import htmlEntityResolver from 'he';
import Errors from '@/utils/Errors';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ChatGptService from '@/services/ChatGptService';
import QuestionServices from '@/services/QuestionServices';
import ShopServices from '@/services/ShopServices';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Tooltip } from 'bootstrap';
import { Modal } from 'bootstrap';
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    topicId: Number,
    topicContent: String,
  },
  setup(props, { emit }) {
    const contentUpdateLoader = ref(false);
    const searchDisabled = ref(false);
    const questions = ref([]);
    const errors_v = reactive(new Errors());
    const editor = ClassicEditor;
    const payloadQuestAns = ref([]);
    const replaceQuestionActive = ref('');
    const replaceSingleQuestionActive = ref('');
    const editModal = ref(null);
    const previewModal = ref(null);
    const confirmDeleteModal = ref(null);
    const confirmDeleteModalId = ref(null);
    const oldTopicId = ref(null);
    const initialState = {
      content: '',
      query:
        'Rewrite Content with better context and HTML tags to improve the clarity and coherence of the text: ',
      editorContent: '',
    };
    const form = reactive(initialState);
    const quesFormInitState = {
      questionContent: '',
      topicQuestion: [],
      questionQuery:
        "rewrite questions and answers with better grammer but don't change the current format of questions and answers: ",
    };
    const questionForm = reactive(quesFormInitState);

    const closeEditModal = () => {
      Modal.getOrCreateInstance(editModal.value).hide();
    };
    const showEditModal = () => {
      Modal.getOrCreateInstance(editModal.value).show();
    };
    const openPreviewModal = () => {
      closeEditModal();
      Modal.getOrCreateInstance(previewModal.value).show();
    };
    const closePreviewModal = () => {
      Modal.getOrCreateInstance(previewModal.value).hide();
      showEditModal();
    };
    const showConfirmDeleteModal = (idx) => {
      confirmDeleteModalId.value = idx;
      Modal.getOrCreateInstance(confirmDeleteModal.value).show();
      closeEditModal();
    };
    const schema = Yup.object().shape({
      editerContent: Yup.string().nullable('Editer Content is required'),
      query: Yup.string().required('Query is required'),
      content: Yup.string().required('Content is required'),
    });
    const questionFormSchema = Yup.object().shape({
      questionQuery: Yup.string().required('Query is required'),
      questionContent: Yup.string().required('Content is required'),
    });


    function resetField(fieldName, schema) {
      const field = document.getElementsByName(fieldName)[0];
      field.value = "";
      schema.validateAt(fieldName, '')
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          if (err.path === fieldName) {
            schema.fields[fieldName].errors = [];
          }
        });
    }

    const copyQuestionToClipboard = async (index) => {
      
      const question = questionForm.topicQuestion[index];
      console.log('-------out form if');
      const textToCopy = `${question.question}\n${question.answers.map((a) => a.answer).join('\n')}`;
      if (navigator.clipboard && window.isSecureContext) {
        console.log('-------above if');
        await navigator.clipboard.writeText(textToCopy).then(() => {
          alert('Text copied to clipboard!');
        })
        .catch(() =>{
          alert('Text not copied to clipboard!');
        });
      }
    }


    const getTopicRelatedData = async () => {
      form.editorContent="";
      resetField('editerContent', schema);
      resetField('content', schema);
      resetField('questionContent', questionFormSchema);
      await ShopServices.getTopicQuestion(props.topicId)
        .then((response) => {
          form.editorContent = htmlEntityResolver.decode(
            response.data.topic ? response.data.topic.content : ''
          );
          questionForm.topicQuestion = response.data.questions;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            closeEditModal();
          }
        });
    };

    const removeQuestion = async (QuestionId) => {
      QuestionServices.removeQuestion(QuestionId)
        .then(() => {
          getTopicRelatedData();
          showEditModal();
          Modal.getOrCreateInstance(confirmDeleteModal.value).hide();
        })
        .catch(() => {
          // console.log(err);
        });
    };
    const hideConfirmDeleteModal = (bool) => {
      bool ? removeQuestion(confirmDeleteModalId.value) : showEditModal();
      Modal.getOrCreateInstance(confirmDeleteModal.value).hide();
    };

   

    onMounted(() => {
      new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
      });
      previewModal.value.addEventListener('hidden.bs.modal', event => {
        closePreviewModal();
      })
      confirmDeleteModal.value.addEventListener('hidden.bs.modal', event => {
        hideConfirmDeleteModal();
      })
      replaceQuestionActive.value = localStorage.getItem('topic_batch')
        ? (replaceQuestionActive.value = localStorage.getItem('topic_batch'))
        : '';

        replaceSingleQuestionActive.value = localStorage.getItem('single_question')
        ? (replaceSingleQuestionActive.value = localStorage.getItem('single_question'))
        : '';
    });
    const questionsAndAnswers = computed(() => {
      let text = '';
      questionForm.topicQuestion.forEach((question, idx) => {
        text += `Question ${idx + 1}: ${question.question}\n`;
        question.answers.forEach((answer, ansIdx) => {
          text += `Answer ${ansIdx + 1}: ${answer.answer}`;
          if (answer.is_correct) {
            text += ' (correct)';
          }
          text += '\n';
        });
      });
      return text;
    });

    const cpTopicQuestion = (actionType, id) => {
      if (actionType == 'copy' && id) {
        localStorage.setItem('topic_batch', id);
        replaceQuestionActive.value = localStorage.getItem('topic_batch');
      } else if (actionType == 'remove' && id) {
        localStorage.removeItem('topic_batch');
        replaceQuestionActive.value = '';
      }
    };

    const cpSingleTopicQuestion = (actionType, id) => {
      oldTopicId.value = props.topicId;
      if (actionType == 'copy' && id) {
        localStorage.setItem('single_question', id);
        replaceSingleQuestionActive.value = localStorage.getItem('single_question');
      } else if (actionType == 'remove' && id) {
        localStorage.removeItem('single_question');
        replaceSingleQuestionActive.value = '';
      }
    };
    const replaceQuestionAnswers = async () => {
      if (replaceSingleQuestionActive.value != '') {
        const payload = {
          copied_question_id: replaceSingleQuestionActive.value,
          replaceable_topic_id: props.topicId,
        };
        await ShopServices.copySingleQuestionAnswers(payload)
          .then(async (response) => {
            if (response.data.status == 'Success') {
              localStorage.removeItem('single_question');
              replaceSingleQuestionActive.value = '';
              getTopicRelatedData();
              emit('alertEvent', 'add');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const payload = {
          copied_topic_id: replaceQuestionActive.value,
          replaceable_topic_id: props.topicId,
        };
        await ShopServices.replaceTopicQuestionAnswers(payload)
          .then(async (response) => {
            if (response.data.status == 'Success') {
              localStorage.removeItem('topic_batch');
              replaceQuestionActive.value = '';
              getTopicRelatedData();
              emit('alertEvent', 'add');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    watch(
      () => props.topicId,
      async (currentValue, oldValue) => {
        if (currentValue != oldValue && currentValue != 0) {
          if (props.topicId > 0) {
            getTopicRelatedData();
          } else {
            closeEditModal();
          }
        }
      }
    );

    const activeLoader = (type, id) => {
      if (type == 'active' && id) {
        const loader = document.getElementById(id);
        loader.classList.remove('d-none');
      } else if (type == 'dactive' && id) {
        const loader = document.getElementById(id);
        loader.classList.add('d-none');
      }
    };
    const rewriteContent = async (query, content, type) => {
      if (query && content && type) {
        activeLoader('active', type);
        await ChatGptService.chatGptCompletion(query + ' ' + content)
          .then((response) => {
            if (
              response.choices &&
              response.choices.length > 0 &&
              response.choices[0]['message']['content']
            ) {
              if (type == 'topic')
                form.content = response.choices[0]['message']['content'];
              if (type == 'question')
                questionForm.questionContent =
                  response.choices[0]['message']['content'];
            }
            activeLoader('dactive', type);
          })
          .catch((error) => {
            console.log(error);
            activeLoader('dactive', type);
          });
      }
    };

    const validateQuestionAnswers = (questionAnswers) => {
      payloadQuestAns.value = questionAnswers;

      const hasValidQuestion = questionAnswers.every(
        (item) => item.question && item.question.trim() !== ''
      );
      if (
        !hasValidQuestion ||
        questionForm.topicQuestion.length !== questionAnswers.length
      ) {
        return false;
      }

      const hasValidAnswers = questionAnswers.every((item) => {
        return (
          Array.isArray(item.answers) &&
          item.answers.length > 0 &&
          item.answers.every((answer) => {
            return (
              answer.option &&
              answer.option.trim() !== '' &&
              typeof answer.is_correct === 'number' &&
              (answer.is_correct === 0 || answer.is_correct === 1)
            );
          }) &&
          item.answers.some((answer) => answer.is_correct === 1)
        );
      });

      return hasValidAnswers;
    };
    const createQuestionAnswerStructure = (text) => {
      const lines = text.split('\n').filter((line) => line.trim() !== '');
      const questions = [];
      let currentQuestion;
      for (const line of lines) {
        if (line.startsWith('Question ')) {
          if (currentQuestion) {
            questions.push(currentQuestion);
          }
          currentQuestion = {
            question: line.slice(line.indexOf(':') + 2),
            answers: [],
          };
        } else if (line.startsWith('Answer ')) {
          const answerText = line.slice(line.indexOf(':') + 2);
          const isCorrect = answerText.includes('(correct)');
          currentQuestion.answers.push({
            option: isCorrect
              ? answerText.replace('(correct)', '').trim()
              : answerText.trim(),
            is_correct: isCorrect ? 1 : 0,
          });
        }
      }

      if (currentQuestion) {
        questions.push(currentQuestion);
      }
      if (questions && questions.length > 0) {
        return validateQuestionAnswers(questions);
      }
      return false;
    };

    const submitTopicQuestion = async (values, actions) => {
      activeLoader('active', 'topicquestion');
      if (createQuestionAnswerStructure(values.questionContent)) {
        const payload = {
          topic_id: props.topicId,
          topic_questions: payloadQuestAns.value,
        };
        await ShopServices.updateTopicQuestionAnswers(payload)
          .then(async (response) => {
            if (response.data.status == 'Success') {
              emit('alertEvent', 'edit');
              getTopicRelatedData();
              actions.resetForm('questionContent');
              activeLoader('dactive', 'topicquestion');
            }
          })
          .catch((error) => {
            console.log(error);
            activeLoader('dactive', 'topicquestion');
          });
      } else {
        activeLoader('dactive', 'topicquestion');
        emit('alertEvent', 'pattern');
      }
    };

    const doSubmit = async (values, actions) => {
      activeLoader('active', 'topicSave');
      const payload = {
        content: htmlEntityResolver.encode(form.content, {
          useNamedReferences: true,
          decimal: false,
        }),
      };
      await ShopServices.updateTopicContent(payload, props.topicId)
        .then(async (response) => {
          if (response.data.status == 'Success') {
            getTopicRelatedData();
            actions.resetForm('content');
            activeLoader('dactive', 'topicSave');
            emit('alertEvent', 'edit');
          }
        })
        .catch((error) => {
          console.log(error);
          activeLoader('dactive', 'topicSave');
        });
    };
    return {
      schema,
      questionFormSchema,
      form,
      doSubmit,
      errors_v,
      searchDisabled,
      questions,
      rewriteContent,
      editor,
      openPreviewModal,
      contentUpdateLoader,
      questionForm,
      submitTopicQuestion,
      questionsAndAnswers,
      cpTopicQuestion,
      replaceQuestionActive,
      replaceQuestionAnswers,
      removeQuestion,
      editModal,
      previewModal,
      confirmDeleteModal,
      closeEditModal,
      showEditModal,
      closePreviewModal,
      showConfirmDeleteModal,
      hideConfirmDeleteModal,
      confirmDeleteModalId,
      cpSingleTopicQuestion,
      replaceSingleQuestionActive,
      oldTopicId,
      copyQuestionToClipboard
    };
  },
};
</script>
