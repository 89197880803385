<template>
    <!-- Inner content -->
    <div class="inner-content">
        <section>
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative py-4">
                                <div class="content-card-left">
                                    <h4 class="card-title text-white">Add Payment</h4>
                                </div> 
                            </div>
                            <div class="card-body px-0" >
                                <div class="header-content">
                                    <Form @submit="onSubmit" :validation-schema="schema" class="custom-form mt-2 shops-product" ref="myForm">
                                        <div class="row mx-0 px-4">
                                            <div class="col-lg-11 col-md-11 col-sm-10 col-9">
                                                <div class="position-relative form-group">
                                                    <Field as="select" name="salesman_id" class="form-select custom-select" @click="selectFocus = true" v-model="salesman_id">
                                                        <option value="0">Choose Salesman</option>
                                                        <option v-for="salesman in salesmen" :value="salesman.id" :key="salesman.id">{{salesman.first_name+" "+salesman.last_name}}</option>
                                                        
                                                    </Field>
                                                    <label :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Salesman</label>
                                                    <i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
                                                    <ErrorMessage class="invalid-message mb-0 pt-2" name="salesman_id" />
                                                    <p v-if="errors_v.get('salesmanId') && (salesman_id==null || salesman_id=='')" class="invalid-message mb-0 pt-2">
                                                        {{errors_v.get('salesmanId')}}
                                                    </p>
                                                </div>
                                            </div>  
                                        </div>
                                        <!-- Shop rows -->
                                        <fieldset class="InputGroup row mx-0" v-for="(shop, idx) in shops" :key="shop.id">
                                            <div class="row mx-0 shop-row mt-4 pt-3 pb-4">  
                                                <div class="col-12 px-0">
                                                    <div class="row mx-0 px-4">
                                                        <div class="col-12" v-if="idx===0">
                                                            <div class="d-flex flex-wrap align-items-center mb-2">
                                                                <h6 class="mb-0 text-primary">Shop</h6>
                                                                <div class="text-center add-rows-wrapper ms-auto">
                                                                    <span class="d-inline-block add bi bi-plus-circle-fill text-primary" @click="add_shop"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" v-else>
                                                            <div class="d-flex flex-wrap align-items-center mb-2">
                                                                <h6 class="mb-0 text-primary">Shop</h6>
                                                                <div class="text-center add-rows-wrapper ms-auto">
                                                                    <span class="d-inline-block add bi bi-dash-circle-fill text-primary" @click="remove_shop(shop)"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 mb-lg-0 mb-md-0 mb-3 col-12">
                                                            <Multiselect @select="changeMultiselectShop(shop.shop_model,idx)"
                                                                v-model="shop.shop_model"
                                                                :options="shopOptions"
                                                                class="custom-multiselect"
                                                                placeholder="Shop"
                                                                :searchable="true"
                                                                :showOptions="true"
                                                                mode="single"
                                                                label="shopName"
                                                                trackBy	= "shop_name"
                                                                valueProp="id"
                                                                required: true
                                                                :id="`multiselect_${idx}`"
                                                                :name="`shops[${idx}].multiselect`"
                                                            />
                                                            <Field type="hidden" :id="`shopid_${idx}`" :name="`shops[${idx}].shopid`" v-model="values_hidden_shops[idx]" />
                                                            <p v-if="errors_v.get('paymentData.'+idx+'.shopid') && (values_hidden_shops[idx]==null || values_hidden_shops[idx]=='')" class="invalid-message mb-0 pt-2">
                                                                {{errors_v.get('paymentData.'+idx+'.shopid')}}
                                                            </p>
                                                            <ErrorMessage class="invalid-message mb-0 pt-2" :name="`shops[${idx}].shopid`" />
                                                        </div>
                                                        <div class="col-lg-2 col-md-2 mb-lg-0 mb-md-0 mb-3 col-12">
                                                            <div class="position-relative">
                                                                <Field type="number" :id="`amount_${idx}`" :name="`shops[${idx}].amount`" :class="[shop.amount=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.amount"/>
                                                                <label for="" class="mb-0 placeholder-label w-auto">Amount</label>
                                                                <p v-if="errors_v.get('paymentData.'+idx+'.amount') && (shops[idx].amount==null || shops[idx].amount=='')" class="invalid-message mb-0 pt-2">
                                                                    {{errors_v.get('paymentData.'+idx+'.amount')}}
                                                                </p>
                                                                <ErrorMessage class="invalid-message mb-0 pt-2" :name="`shops[${idx}].amount`" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-md-2 mb-lg-0 mb-md-0 mb-3 col-12">
                                                            <div class="position-relative">
                                                                <Field type="text" :id="`paymentType_${idx}`" :name="`shops[${idx}].paymentType`" :class="[shop.paymentType=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="shop.paymentType"/>
                                                                <label for="" class="mb-0 placeholder-label w-auto">Payment Type</label>
                                                                
                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
                                            <button class="btn btn-primary w-100" :disabled="searchDisabled">
                                                <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Payment
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
	import { ref, reactive, onMounted, watch } from 'vue';
    import "@/assets/scss/pages/_add-billing.scss";
    // Add this for multiselect
	import Multiselect from '@vueform/multiselect';
	import "@vueform/multiselect/themes/default.css";
    import salesmanServices from '@/services/SalesmanServices';
    import salesmanShopServices from '@/services/SalesmanShopServices';
    import billingServices from '@/services/BillingServices';
    import {Form, Field, ErrorMessage} from 'vee-validate';
    import Errors from "@/utils/Errors";
    import * as yup from "yup";
    import {useRouter} from "vue-router";

	export default {
	    components: {
             Multiselect,
             Form,
             Field,
             ErrorMessage
	    },
		setup() {
            const salesmen=ref([]);
            const salesman_id=ref(0);
            const shopOptions=ref("");
            const loader=ref(false);
            const router = useRouter();
            const searchDisabled=ref(false);
            const shops=ref([{
                id: Date.now(),
                shop_model: "",
                amount: "",
                paymentType: ""
            }]);
            const errors_v = reactive(new Errors());
            const values_hidden_shops=ref({});
            const schema = yup.object().shape({
            salesman_id: yup.number().moreThan(0, 'Salesman Required'),
            shops: yup
                .array()
                .of(
                yup.object().shape({
                    shopid: yup.number().required().label("Shop Name"),
                    amount: yup.number().moreThan(0, 'Amount Required').typeError("Only Numbers Allowed").label("Amount"),  
                })
                )		
		    });
            const add_shop= ()=>{
                shops.value.push({
                    id: Date.now(),
                    shop_model: "",
                    amount: "",
                    paymentType: ""
                });
		    }
            const remove_shop=(item)=>{
                const index = shops.value.indexOf(item);
                if (index === -1){
                    return;
                }
                shops.value.splice(index, 1);
            }
            async function changeMultiselectShop(value,idx){
                values_hidden_shops.value[idx]=value;
            }
            function resetCustomForm() {
                shops.value.length=0;
                shops.value=[{
                    id: Date.now(),
                    shop_model: "",
                    amount: "",
                    paymentType: ""
                }]
                values_hidden_shops.value={};
            }
            
            watch(()=>salesman_id.value, (currentValue, oldValue) => {
                if(currentValue!=oldValue && currentValue!=""){
                    salesmanShopServices.getSalesmanShops(salesman_id.value)
                    .then(response=>{
                        console.log(response.data.data);
                        shopOptions.value= response.data.data;
                        
                    })
                    resetCustomForm(); 
                }
            });
            onMounted(async () => {
                await salesmanServices.paginateSalesman()
                .then(response=>{
                    salesmen.value= response.data.data;
                })
            });

            const onSubmit = async(values, actions) => {
			    loader.value=true;
                searchDisabled.value=true;
                const payload={
                "paymentData": values.shops,
                "salesmanId": values.salesman_id
			    }
                await billingServices.addCredit(payload)
                .then(async response=>{
                    resetCustomForm();
                    loader.value=false;
                    searchDisabled.value=false;
                    
                    actions.resetForm();
                    //shop_id.value=0;
                    //shopOptions.value="";
                    router.push('/billing');
                    //emit('alertEvent', 'add');
                })
                .catch(error => {
                    loader.value=false;
                    searchDisabled.value=false;
                    if(error.response.status==422){
                        errors_v.record(error.response.data.errors);
                    }
                });
			}
			const selectFocus = ref(false);
			return {
				selectFocus,
                shops,
                add_shop,
                remove_shop,
                changeMultiselectShop,
                salesmen,
                salesman_id,
                shopOptions,
                onSubmit,
                values_hidden_shops,
                errors_v,
                schema,
                resetCustomForm,
                loader,
                searchDisabled,
                router
			}
		}
	}
</script>