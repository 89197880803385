<template>
    <!-- Add Modal -->
<div class="modal fade p-0" id="addShop" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg">
		<div class="d-flex flex-wrap w-100 position-relative event-auto">
			<div class="card shadow-none">
				<Form @submit="onSubmit" @keydown="errors_v.clear($event.target.name)" :validation-schema="schema" class="custom-form mt-2 assign-shops">
					
						<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
							<div class="content-card-left w-100">
								<h4 class="card-title text-white fs-4">Assign Shops</h4>
								<p class="fw-light text-white mb-0 pt-1">Assign shops to Salesman</p>
							</div>
						</div>
						<fieldset class="InputGroup" v-for="(user, idx) in users" :key="user.id">
							<div class="card-body" >
								
								<div class="row mx-0" >
									<div class="col-12" v-if="idx==0">
										<div class="d-flex flex-wrap aligm-items-center mb-4">
											<h6 class="mb-0 text-primary">Shop</h6>
											<div class="text-center align-self-center add-rows-wrapper ms-auto">
												<span class="d-inline-block add bi bi-plus-circle-fill text-primary" @click="add"></span>
											</div>
										</div>
									</div>
									<div class="col-12" v-else>
										<div class="d-flex flex-wrap aligm-items-center mb-4">
											<h6 class="mb-0 text-primary">Shop</h6>
											<div class="text-center align-self-center add-rows-wrapper ms-auto">
												<span class="d-inline-block add bi bi-dash-circle-fill text-primary" @click="remove(user)"></span>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
										<div class="position-relative form-group">
											<Field as="select" class="form-select custom-select" @click="selectFocus = true" :id="`day_${idx}`" :name="`users[${idx}].day`" v-model="user.day_model">
												<option selected>Monday</option>
												<option value="tuesday">Tuesday</option>
												<option value="wednesday">Wednesday</option>
												<option value="thursday">Thursday</option>
												<option value="friday">Friday</option>
												<option value="saturday">Saturday</option>
												<option value="sunday">Sunday</option>
											</Field>
											<label :for="`day_${idx}`" :class="[selectFocus ? 'focus':'', 'position-absolute select-label']">Day</label>
											<i class="bi bi-caret-down-fill select-arrow position-absolute"></i>
											<ErrorMessage class="invalid-message mb-0 pt-2" :name="`users[${idx}].day`" />
											<p v-if="errors_v.get('shopsData.'+idx+'.day') && (user.day_model==null || user.day_model=='')" class="invalid-message mb-0 pt-2">
												{{errors_v.get('shopsData.'+idx+'.day')}}
											</p>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
										
										<Multiselect @select="changeMultiselect(user.shop_count,idx)"
											v-model="user.shop_count"
											:options="modalOptions"
											class="custom-multiselect"
											placeholder="Shop"
											:searchable="true"
											:showOptions="true"
											mode="single"
											label="shopName"
											trackBy	= "shop_name"
											valueProp="id"
											required: true
											:id="`multiselect_${idx}`"
											:name="`users[${idx}].multiselect`"
										/>
										
										<Field type="hidden" :id="`shopid_${idx}`" :name="`users[${idx}].shopid`" v-model="values_hidden[idx]" />
										<ErrorMessage class="invalid-message mb-0 pt-2" :name="`users[${idx}].shopid`" />
										<p v-if="errors_v.get('shopsData.'+idx+'.shopid') && (values_hidden[idx]==null || values_hidden[idx]=='')" class="invalid-message mb-0 pt-2">
											{{errors_v.get('shopsData.'+idx+'.shopid')}}
										</p>
									</div>
									
									<div class="col-lg-4 col-md-4 mb-lg-0 mb-md-0 mb-3 col-12">
										<div class="position-relative">
											<Field as="input" :id="`balance_${idx}`" :name="`users[${idx}].balance`" type="number" :class="[balance=='' ? '' : 'active', 'border-input position-relative w-100']" v-model="user.balance_model"/>
											<label :for="`balance_${idx}`" class="mb-0 placeholder-label w-auto">Balance</label>
											<ErrorMessage class="invalid-message mb-0 pt-2" :name="`users[${idx}].balance`" />
											<p v-if="errors_v.get('shopsData.'+idx+'.balance') && (user.balance_model==null || user.balance_model=='')"  class="invalid-message mb-0 pt-2">
												{{errors_v.get('shopsData.'+idx+'.balance')}}
											</p>
										</div>
									</div>
									
								</div>
								
							
							</div>
						</fieldset>
					<div class="card-footer px-4 pb-0 border-0 bg-transparent mb-3">
						<button class="btn btn-primary w-100" :disabled="searchDisabled">
                  <div v-if="loader" class="spinner-border me-2" role="status"></div>Add Shops
                </button>
					</div>
				</Form>
			</div>
		</div>
	</div>			
</div>
</template>

<script>
	import { onMounted, ref, reactive} from 'vue';
    //import "@/assets/scss/pages/_salesman-shop.scss";
	// Add this for multiselect
	import Multiselect from '@vueform/multiselect';
	import "@vueform/multiselect/themes/default.css";
	import * as yup from "yup";
    import {Form, Field, ErrorMessage} from 'vee-validate';
	import shopServices from '@/services/ShopServices'; 
	import SalesmanShopServices from '@/services/SalesmanShopServices';
	import Errors from "@/utils/Errors";
	export default {
	    components: {
	         Multiselect,
			 Form,
			 Field,
			 ErrorMessage
	    },
		props:{
			salesman_id: Number
		},
		setup(props,{emit}) {
			const loader=ref(false);
        	const searchDisabled=ref(false);
			const errors_v = reactive(new Errors());
			const users=ref([{
				id: Date.now(),
				shop_count: "",
				balance_model: 0,
				day_model: ""
				}]);
			const values_hidden=ref({});
			const modalOptions = ref("");
			const schema = yup.object().shape({
				users: yup
					.array()
					.of(
					yup.object().shape({
						day: yup.string().required().label("Day"),
						shopid: yup.number().required().label("Shop Name"),
						balance: yup.number().typeError("Only Numbers Allowed").label("Balance"),
					})
					)
					
			});
			onMounted(async () => {
				await shopServices.getAllShops()
            	.then(response=>{
                 	modalOptions.value= response.data;
				 	console.log(modalOptions);
            	})
			});
			const add= ()=>{
				users.value.push({
					id: Date.now(),
					shop_count: "",
					balance_model: 0,
					day_model: ""
				});
			}

    		const remove=(item)=>{
      			const index = users.value.indexOf(item);
				if (index === -1){
					return;
				}
      			users.value.splice(index, 1);
    		}
			function changeMultiselect(value,idx){
				values_hidden.value[idx]=value;
			}
			const closeModal= ()=>{
            const myModalEl = document.getElementById('addShop');
            const modal = window.bootstrap.Modal.getInstance(myModalEl);
            modal.hide();
          	}
			const onSubmit = async(values, actions) => {
				loader.value=true;
          		searchDisabled.value=true;
				//alert(JSON.stringify(values, null, 2));
				const payload={
				"shopsData": values.users,
				"salesmanId": props.salesman_id
				}
				await SalesmanShopServices.addSalesmanShops(payload)
              	.then(async response=>{
                	closeModal();
                	loader.value=false;
                	searchDisabled.value=false;
                	emit('alertEvent', 'add');
              })
              .catch(error => {
				  loader.value=false;
				  searchDisabled.value=false;
					if(error.response.status==422){
						errors_v.record(error.response.data.errors);
					}
              });
			}



			
	        
			
			const selectFocus = ref(false);
			
			return {
				selectFocus,
				users,
				schema,
				add,
				remove,
				onSubmit,
				modalOptions,
				values_hidden,
				changeMultiselect,
				closeModal,
				loader,
				searchDisabled,
				errors_v

	        }
		}
	}
</script>