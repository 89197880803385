<template>
  <!-- Inner content -->
  <div class="inner-content">
    <section>
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12">
            <div class="card">
              <div
                class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative"
              >
                <div class="content-card-left">
                  <h4 class="card-title text-white">Companies</h4>
                </div>
                <div class="content-card-right ms-auto d-flex flex-wrap">
                  <a
                    href="#addCompany"
                    data-bs-toggle="modal"
                    data-bs-target="#addCompany"
                    @click.prevent
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle"
                  >
                    <i class="bi bi-plus-square-fill text-white"></i>
                  </a>

                  <a
                    href="#"
                    class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle me-1"
                    @click="counter_pdf_all"
                  >
                    <img
                      src="@/assets/images/pdf.png"
                      alt="pdf-image"
                      class="small-image white-image"
                    />
                  </a>
                </div>
              </div>
              <div class="card-body">
                <div class="header-content">
                  <div class="table-responsive mb-0">
                    <table id="my-table" class="custom-table shop-table">
                      <thead>
                        <tr>
                          <th class="text-capitalize">Id</th>
                          <th class="text-capitalize">Name</th>
                          <th class="text-capitalize">Type</th>
                          <th class="text-capitalize">Mobile</th>
                          <th class="text-capitalize">Address</th>
                          <th class="text-capitalize text-center">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="company in companies" :key="company.id">
                          <td>{{ company.id }}</td>
                          <td>{{ company.name }}</td>
                          <td>{{ company.type }}</td>
                          <td>{{ company.mobile }}</td>
                          <td>{{ company.address }}</td>
                          <td class="action text-center">
                            <a
                              href="#"
                              class="edit d-inline-block me-2"
                              data-bs-toggle="modal"
                              data-bs-target="#editCompany"
                              @click="edit_id = company.id"
                            >
                              <i class="bi bi-pencil-fill"></i>
                            </a>
                            <router-link
                              :to="`/companies/products/${company.id}`"
                              class="d-inline-block"
                            >
                              <i class="bi bi-cart-fill"></i>
                            </router-link>
                            <a
                              href="#"
                              class="edit d-inline-block text-center"
                              @click="updatePdfId(company)"
                            >
                              <img
                                src="@/assets/images/pdf.png"
                                alt="pdf-image"
                                class="small-image"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <company-add-modal @alert-event="showGrowl"></company-add-modal>
  <company-edit-modal
    @alert-event="showGrowl"
    :company_id="edit_id"
  ></company-edit-modal>
  <company-products-pdf
    :company_id="company_id"
    :count_number="count_number"
    :company_name="company_name"
  ></company-products-pdf>
  <total-companies-pdf :counter_all="counter_all"></total-companies-pdf>
  <Alert
    v-if="growlMessage"
    :name="alert_m.name"
    :alertHeading="alert_m.heading"
    :alertDescription="alert_m.description"
  >
  </Alert>
</template>
<script>
import '@/assets/scss/pages/_billing.scss';
import companyServices from '@/services/CompanyServices';
import { onMounted, ref, reactive } from 'vue';
import CompanyAddModal from '@/components/modals/CompanyAddModal';
import CompanyEditModal from '@/components/modals/CompanyEditModal';
import Alert from '@/components/alert';
import CompanyProductsPdf from '@/components/pdf/CompanyProductsPdf.vue';
import TotalCompaniesPdf from '@/components/pdf/TotalCompaniesPdf.vue';
export default {
  name: 'Companies',
  components: {
    CompanyAddModal,
    CompanyEditModal,
    Alert,
    CompanyProductsPdf,
    TotalCompaniesPdf,
  },
  setup() {
    const companies = ref([]);
    const count_number = ref(0);
    const company_id = ref(0);
    const company_name = ref('');
    const counter_all = ref(0);
    const updatePdfId = (cobj) => {
      company_id.value = cobj.id;
      count_number.value++;
      company_name.value = cobj.name;
    };

    const loadData = async () => {
      await companyServices.paginateCompanies().then((response) => {
        companies.value = response.data.data;
      });
    };

    const edit_id = ref(0);
    const alert_m = reactive({
      name: '',
      heading: '',
      description: '',
    });
    const growlMessage = ref(false);
    const showGrowl = (alertType) => {
      if (alertType == 'add') {
        alert_m.name = 'success';
        alert_m.description = 'Record Added Successfully';
      } else if (alertType == 'edit') {
        alert_m.name = 'success';
        alert_m.description = 'Record Updated Successfully';
      } else {
        alert_m.name = 'danger';
        alert_m.description = 'No Record Found. Please try with valid data';
      }

      alert_m.heading = 'Company';
      growlMessage.value = true;
      setTimeout(() => {
        growlMessage.value = false;
      }, 5000);
    };
    const counter_pdf_all = () => {
      counter_all.value++;
    };

    onMounted(() => {
      loadData();
    });

    return {
      companies,
      loadData,
      showGrowl,
      alert_m,
      growlMessage,
      edit_id,
      company_id,
      count_number,
      updatePdfId,
      company_name,
      counter_pdf_all,
      counter_all,
    };
  },
};
</script>
