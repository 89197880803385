export default {
    async chatGPTRes(question){
        const sQuestion = question.replace(/^\s+|\s+$/gm,'');
       // const selLang ="en-US";
        if (sQuestion == "") {
            alert("Type in your question!");
            return;
        }
        const sModel ='gpt-3.5-turbo';
        // const iMaxTokens = 2048;
        // const sUserId = "1";
        // const dTemperature = 0.5;    
    
        const data = {
            model: sModel,
            messages : [
                {'role' : 'user',
                'content' : sQuestion
                }
            ]
            
            //prompt: sQuestion,
            // max_tokens: iMaxTokens,
            // user: sUserId,
            // temperature:  dTemperature,
            // frequency_penalty: 0.0, 
            // presence_penalty: 0.0,  
        };
        return new Promise( (resolve, reject) => {
            const oHttp = new XMLHttpRequest();
            oHttp.open("POST", "https://api.openai.com/v1/chat/completions");
            oHttp.setRequestHeader("Accept", "application/json");
            oHttp.setRequestHeader("Content-Type", "application/json");
            oHttp.setRequestHeader("Authorization", "Bearer " + process.env.VUE_APP_OPENAI_API_KEY)
            oHttp.send(JSON.stringify(data));
            oHttp.onreadystatechange = function () {
                if (oHttp.readyState === 4 && oHttp.status===200) {
                    try {
                        resolve(JSON.parse(oHttp.responseText));
                    } catch (ex) {
                        reject({
                            error:ex
                        })
                    }
                }
            };
        });   
        
    }
};