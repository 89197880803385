<template>
			<!-- Inner content -->
			<div class="inner-content">
				<section>
					<div class="container-fluid">
						<div class="row m-0">
							<div class="col-12">
								<div class="card">
									<div class="card-header card-header-primary d-flex flex-wrap align-items-center position-relative">
										<div class="content-card-left">
											<h4 class="card-title text-white">Products</h4>
										</div>
										<div class="content-card-right ms-auto">
											<a href="#addProduct" data-bs-toggle="modal" data-bs-target="#addProduct" @click.prevent class="btn btn-add d-flex flex-wrap align-items-center justify-content-center rounded-circle">
												<i class="bi bi-plus-square-fill text-white"></i>
											</a>
										</div>
									</div>
									<div class="card-body" >
										<div class="header-content">
											 <form
                    class="table-header mx-0 mb-1 custom-form position-sticky"
                  >
                    <div class="row m-0 align-items-center">
                      <div
                        class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 px-0"
                      >
                        <h4 class="filter mb-lg-0 mb-md-0 mb-3">
                          <i class="bi bi-funnel me-1 position-relative"></i
                          >Filters
                        </h4>
                      </div>
                      <div
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 px-0 ms-lg-auto ms-md-auto"
                      >
                        <div class="position-relative w-100">
                          <Multiselect v-model="searchData"
                          :options="products_search" class="custom-multiselect
                          table-header-multi h-auto" placeholder="Search Product"
                          :searchable="true" :showOptions="true" mode="single"
                          label="name" trackBy = "name" valueProp="id"
                          required: true />

                          <i
                            class="bi bi-search position-absolute search-multi"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </form>
											<div class="table-responsive mb-0">
												<table id="my-table"  class="custom-table product-table">
													<thead class="small-font">
														<tr>
															<th class="text-capitalize text-center">Id</th>
															<th class="text-capitalize text-center">Name</th>
															<th class="text-capitalize text-center">Quantity</th>
															<th class="text-capitalize text-center">Boxes Per Carton</th>
															<th class="text-capitalize text-center">Price Per Unit</th>
															<th class="text-capitalize text-center">Discount Type</th>
                                                            <th class="text-capitalize text-center">Discount</th>
															<th class="text-capitalize text-center">Price After Discount</th>
															<th class="text-capitalize text-center">Expense</th>
															<th class="text-capitalize text-center">Total Purchase Price</th>
															<th class="text-capitalize text-center">Whole Sale Price</th>
                                                            <th class="text-capitalize text-center">Retail Price</th>

															<th class="text-capitalize text-center">actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="product in products" :key="product.id">
															<td>{{product.id}}</td>
															<td class="text-center">{{product.name}}</td>
															<td class="text-center">{{product.quantity}}</td>
															<td class="text-center">{{product.boxes_per_carton}}</td>
															<td class="text-center">{{product.initial_price_per_unit}}</td>
															<td v-if="product.discount_type">{{product.discount_type}}</td>
                          									<td v-else>---</td>
                                                            <td class="text-center">{{product.discount}}</td>
															<td v-if="product.discount_type && product.discount_type==='rupees'">{{product.initial_price_per_unit-product.discount}}</td>
															<td v-else-if="product.discount_type && product.discount_type==='percent'">{{getDiscountedPrice(product.initial_price_per_unit, product.discount)}}</td>
                          									<td v-else>---</td>
															
															<td class="text-center">{{product.expense_per_unit}}</td>
															<td class="text-center">{{getFinalPrice(product.initial_price_per_unit,product.discount_type,product.discount,product.expense_per_unit)}}</td>
                                                            <td class="text-center">{{product.whole_sale_price}}</td>
															<td class="text-center">{{product.retail_price}}</td>
															<td class="action text-center">
																<a href="#" class="edit d-inline-block me-2" data-bs-toggle="modal" data-bs-target="#editProduct" @click="edit_id = product.id">
																	<i class="bi bi-pencil-fill"></i>
																</a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
										
									</div>
									<div class="card-footer card-footer custom-pagination d-flex flex-wrap justify-content-between m-0 px-4 pb-0 border-0 bg-transparent">
                <p class="d-lg-inline-block table-data">
                  Showing
                  {{ current_page * entries_per_page - (entries_per_page - 1) }}
                  to
                  {{
                    current_page * entries_per_page -
                    (entries_per_page - products.length)
                  }}
                  of {{ total_count }} entries.
                </p>
                
                <!--<pagination :v-model="page" :records="44" :per-page="15" @paginate="myCallback"/>-->
                <div class="d-inline-block ms-auto me-lg-0 me-md-0 me-sm-0 me-auto mb-lg-0 mb-md-0 mb-sm-0 mb-4 pagination-wrapper">
                <v-pagination class="d-flex flex-lg-wrap flex-md-wrap flex-sm-wrap align-items-center p-0 m-0"
                  v-model="current_page"
                  :pages="total_pages"
                  :range-size="3"
                  
                  @update:modelValue="loadData"
                />
                </div>
              </div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		
	<product-add-modal :company_id="company_id"  @alert-event="showGrowl"></product-add-modal>
	<product-edit-modal :company_id="company_id" :product_id="edit_id"  @alert-event="showGrowl"></product-edit-modal>
	<Alert
      v-if="growlMessage"
      :name="alert_m.name"
      :alertHeading="alert_m.heading"
      :alertDescription="alert_m.description">
  </Alert>
</template>
<script>
	import { onMounted, ref, reactive,watch } from 'vue';
    import "@/assets/scss/pages/_billing.scss";
	import CompanyServices from '@/services/CompanyServices';
	import VPagination from "@hennge/vue3-pagination";
	import ProductAddModal from '@/components/modals/ProductAddModal';
	import ProductEditModal from '@/components/modals/ProductEditModal';
	import Alert from "@/components/alert";
	// Add this for multiselect
	import Multiselect from '@vueform/multiselect';
	import '@vueform/multiselect/themes/default.css';

	export default {
		props: {
    		company_id: Number
  		},
		components:{
			VPagination,
			ProductAddModal,
			ProductEditModal,
			Alert,
			Multiselect
		},
		setup(props) {
			const total_count=ref(0);
			const entries_per_page=ref(0);
			const products=ref([]);
			const products_search=ref([]);
			const company_id = ref(props.company_id);
			const edit_id=ref(0);
			const total_pages= ref(0);
    		const current_page= ref(1);
			const alert_m = reactive({
				name: '',
				heading: '',
				description: ''
			});
			const searchData = ref('')
			const growlMessage = ref(false);
			const showGrowl = (alertType) =>{
				if(alertType=="add"){
					alert_m.name="success";
					alert_m.description="Record Added Successfully";
				}
				else if(alertType=="edit"){
					alert_m.name="success";
					alert_m.description="Record Updated Successfully";
				}
				else{
					alert_m.name="danger";
					alert_m.description="No Record Found. Please try with valid data";
				}
      
      
				alert_m.heading="Product";
				growlMessage.value=true;
				setTimeout(() => { growlMessage.value=false; }, 5000)
    		}
			const getFinalPrice= (initial_price,discount_type,discount,expense)=>{
				let final_price=0;
				if (discount_type=="rupees"){
					final_price=initial_price-discount;
					final_price=parseFloat(final_price)+parseFloat(expense);
					return parseFloat(final_price).toFixed(2);
				}
				else if(discount_type=="percent"){
					final_price=initial_price-(initial_price*discount/100);
					final_price=parseFloat(final_price)+parseFloat(expense);
					return parseFloat(final_price).toFixed(2);
				}
				
				return parseFloat(initial_price)+parseFloat(expense);
			}
			const getDiscountedPrice= (price,discount)=>{
				const fprice=price-(price*discount/100);
				return fprice.toFixed(2);
			}
			const loadData= async()=>{
      			await CompanyServices.paginateProducts(props.company_id,current_page.value,searchData.value)
      			.then(response=>{
        			products.value= response.data.data;
					total_pages.value=response.data.last_page;
        			current_page.value=response.data.current_page;
					entries_per_page.value=response.data.per_page;
					total_count.value=response.data.total;
      			});
    		}
				const loadProducts= async()=>{
      			CompanyServices.searchProducts(props.company_id,current_page.value)
      			.then(response=>{
        			products_search.value= response.data.data;
      			});
    		}
			onMounted( () => {
				loadData();
				loadProducts();
			});
    watch(
      () => searchData.value,
      (currentValue, oldValue) => {
        if (currentValue == null) {
          searchData.value = '';
        }
        loadData();
      }
    );
			return {
				products,
				total_pages,
				current_page,
				loadData,
				getDiscountedPrice,
				getFinalPrice,
				edit_id,
				alert_m,
				growlMessage,
				showGrowl,
				entries_per_page,
				total_count,
				searchData,
				products_search,

	        }
		}
	}
</script>